import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { AuthProvider } from "./hooks/useAuth";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { HelmetProvider } from "react-helmet-async";
import { SnackbarProvider } from "notistack";
import { RouterProvider } from "react-router-dom";
import router from "./routes/router";
import theme from "./theme";
import { ApolloProvider, apolloClient } from "./apollo";
import { DrawerProvider } from "./hooks/useDrawerToggle";
import { FirstLoadProvider } from "./hooks/useSkeloton";

function App() {
  const helmetContext = {};
  // console.log("GraphQL API URL:", process.env.REACT_APP_GRAPHQL_API_URL);

  return (
    <>
      <ApolloProvider client={apolloClient}>
        <HelmetProvider context={helmetContext}>
          <AuthProvider>
            <DrawerProvider>
              <FirstLoadProvider>
                <ThemeProvider theme={theme}>
                  <SnackbarProvider maxSnack={3}>
                    <CssBaseline />
                    <RouterProvider router={router} />
                    <React.Suspense fallback="loading">
                      {/* <App /> */}
                      {/* <MainLayout/> */}
                    </React.Suspense>
                  </SnackbarProvider>
                </ThemeProvider>
              </FirstLoadProvider>
            </DrawerProvider>
          </AuthProvider>
        </HelmetProvider>
      </ApolloProvider>,
    </>
  );
}

export default App;
