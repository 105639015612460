import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Paper,
  Typography,
  TableContainer,
  Skeleton,
} from "@mui/material";
import DetailDialog from "./DetailDialog"; // Import the dialog component
import { DtcOccurrence } from "../../../types"; // Adjust the import path as needed
import { useFirstLoad } from "../../../hooks/useSkeloton";

interface RegenerationTableProps {
  dieselDtcs: DtcOccurrence[];
}

const RegenerationTable: React.FC<RegenerationTableProps> = ({ dieselDtcs }) => {
  // Define the categories and their respective DTCs for different regeneration levels
  const categories = [
    {
      name: "Level 1 error (23-27) (25% torque reduction) Amber (110-125%)",
      dtc: "P2463",
    },
    {
      name: "Level 2 error (27-34.5) (1600 RPM limit) Red (125-150%)",
      dtc: "P243F",
    },
    { name: "Too frequent regeneration", dtc: "P243C" },
    { name: "Regeneration Lock", dtc: "P2458" },
    { name: "Soot mass Above 34.5", dtc: "P246C" },
  ];

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedName, setSelectedName] = useState<string>("");
  const {isFirstLoad} =useFirstLoad();

  const handleClickOpen = (name: string) => {
    setSelectedName(""); // Reset selectedName temporarily to force re-render
    setTimeout(() => {
      setSelectedName(name);
      setDialogOpen(true);
    }, 0);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedName("");
  };

  // Create the table data
  const tableData = categories.map((category) => {
    const dtcData = dieselDtcs.find((dtc) => dtc.dtc === category.dtc);
    
    return {
      category: category.name,
      dtc: category.dtc,
      count: dtcData ? dtcData.count : 0,
    };
  });

  return (
    <Box sx={{ mt: 2 }}>
      <Paper elevation={3} sx={{ width: "100%",mb: 1, overflow: "hidden", height: "390px" }}>
        <Box sx={{ p: 2  }}>
          <Typography variant="h6" sx={{ color: "white" }}>
            Regeneration Vehicles
          </Typography>
        </Box>
        <TableContainer sx={{overflowY: "auto" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>DPF Categories</TableCell>
                <TableCell>Error Code</TableCell>
                <TableCell>No. of Vehicles</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ fontWeight: "bold" }}>{row.category}</TableCell>
                  <TableCell 
                    sx={{ fontWeight: "bold", cursor: 'pointer', '&:hover': { backgroundColor: '#f5f5f5', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' } }} 
                    onClick={() => handleClickOpen(row.dtc)}
                  >
                    {isFirstLoad ? <Skeleton width={80} /> : row.dtc}
                  </TableCell>
                  <TableCell 
                    sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#f5f5f5', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' } }} 
                    onClick={() => handleClickOpen(row.dtc)}
                  >
                    {isFirstLoad ? <Skeleton width={50}/> : row.count.toString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <DetailDialog open={dialogOpen} onClose={handleClose} name={selectedName} queryType={"DTC"} />
    </Box>
  );
};

export default RegenerationTable;
