// context/FirstLoadContext.tsx
import React, { createContext, useContext, useEffect, useState } from "react";

// Create the context
const FirstLoadContext = createContext({
  isFirstLoad: true,
  markLoaded: () => {},
});

// Create the provider
export const FirstLoadProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const markLoaded = () => {
    setIsFirstLoad(false);
  };

  useEffect(() => {
    // Reset to true when the component is unmounted
    return () => {
      setIsFirstLoad(true);  // Reset to true on unmount
    };
  }, []);

  useEffect(() => {
    // Mark as loaded on initial mount
    if (isFirstLoad) {
      setIsFirstLoad(true);
    }
  }, []);

  return (
    <FirstLoadContext.Provider value={{ isFirstLoad, markLoaded }}>
      {children}
    </FirstLoadContext.Provider>
  );
};

// Custom hook to use the context
export const useFirstLoad = () => {
  return useContext(FirstLoadContext);
};
