import React from "react";
import { Grid, Typography, Paper } from "@mui/material";
import Service from "../../../assets/smlIcons/Dashboard Icons/Service.svg";
import CardTitle from "../HealthCountComponents/CardTItle";
import theme from "../../../theme";
import { StatusOccurence } from "../../../types";

type ServiceStatus = {
  status: "NO_SERVICE_DUE" | "SERVICE_DUE";
  count: number;
};

type ServiceStatusCardProps = {
  serviceStatus: StatusOccurence[];
};

const ServiceStatusCard: React.FC<ServiceStatusCardProps> = ({ serviceStatus }) => {
  // Extract counts based on status
  const countNoServiceDue = serviceStatus.find((status) => status.status === "NO_SERVICE_DUE")?.count || 0;
  const countServiceDue = serviceStatus.find((status) => status.status === "SERVICE_DUE")?.count || 0;

  return (
    <Grid item xs={12}>
      <Paper elevation={3} sx={{ backgroundColor: "white", paddingBottom: "0.45rem" }}>
        <Grid container display={"flex"}>
          <Grid item xs={12} p={1.1}>
            <Grid container alignItems={"center"} justifyContent={"space-between"}>
              <Grid item>
                <CardTitle Title="Service Status" Icon={Service} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <hr style={{ backgroundColor: "#F6F5F4", border: "none", height: "2px" }} />
          </Grid>
          <Grid container item xs={12} columnGap={1} justifyContent={"space-between"} padding={"16px"}>
            <Grid item xs={5.8} sx={{ backgroundColor: theme.palette.lightOrange.main, borderRadius: "10px" ,padding: '10px'}}>
            <Typography fontWeight="600" fontSize="1.5rem" style={{ color: theme.palette.lightOrangeFont.main }}>
                {0}
            </Typography>
            <Typography
                fontWeight="500"
                fontSize="0.8rem"
                style={{ color: theme.palette.lightOrangeFont.main, whiteSpace: "nowrap" }}
            >
               Service Due
            </Typography>
            </Grid>
            <Grid item xs={5.8} sx={{ backgroundColor: "#FFC7CE", borderRadius: "10px" ,padding: '10px'}}>
            <Typography fontWeight="600" fontSize="1.5rem" style={{ color: "#9C2D75" }}>
                {0}
            </Typography>
            <Typography
                fontWeight="500"
                fontSize="0.8rem"
                style={{ color: "#9C2D75", whiteSpace: "nowrap" }}
            >
                Service Overdue
            </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ServiceStatusCard;
