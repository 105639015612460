import { Box, BoxProps } from "@mui/material";
import DashboardIcon from "../../assets/DrawerMenuIcons/Zones.svg";
import VehicleDiagnostic from "../../assets/wrench.svg";
import Hierarachy from "../../assets/hierarchy.svg";
import Download from "../../assets/download.svg";
import VehiclePerformance from "../../assets/DrawerMenuIcons/Truck.svg";
import Account from "../../assets/smlIcons/PerformanceIcons/User.svg";
import Setting from "../../assets/smlIcons/PerformanceIcons/settings.svg"
import DSM from "../../assets/DSM.svg"
import CustomDTC from "../../assets/DrawerMenuIcons/Dtc_edit-01.svg"

const getSVGComponent = (svgPath: string) => {
    const fn = Object.defineProperty(
        function (props: BoxProps) {
            return <Box component="img"  height={"36px"} width={"36px"} src={svgPath} {...props} />;
        },
        "name",
        { value: svgPath.split("/").at(-1) + "SVGImage" },
    );
    return fn;
};

const DrawerItems = [
    {
        text: "Dashboard",
        key: "dashboard",
        path: "/dashboard",
        icon: getSVGComponent(DashboardIcon),
        children: [],
    },
    {
        text: "Vehicle Diagnostics",
        key: "vehicle-diagnostics",
        path: "/vehicle-diagnostics",
        icon: getSVGComponent(VehicleDiagnostic),
        children: [],
    },
    {
        text: "Vehicle Performance",
        key: "vehicle-performance",
        path: "/vehicle-performance",
        icon: getSVGComponent(VehiclePerformance),
        children: [],
    },

    {
        text: "Download Data",
        key: "download-data",
        path: "/download-data",
        icon: getSVGComponent(Download),
        children: [],
    },
    {
        text: "Settings",
        key: "settings",
        path: "/settings/custom-dtc",
        icon: getSVGComponent(Setting),
        children: [
            {
                text: "Search Custom DTC",
                key: "custom-dtc",
                path: "/settings/custom-dtc",
                icon: getSVGComponent(CustomDTC), 
                children: [],
            },
            {
                text: "Manage DTC Backend",
                key: "dsmstrategy",
                path: "/settings/dsmstrategy",
                icon: getSVGComponent(DSM),
                children: [],
            },
            // {
            //     text: "Service Team Hierarchy",
            //     key: "hierarchy-data",
            //     path: "/settings/hierarchy-data",
            //     icon: getSVGComponent(Hierarachy),
            //     children: [],
            // },
        ],
    },
    
    {
        text: "Account",
        key: "account",
        path: "/account",
        icon: getSVGComponent(Account),
        children: [],
    },
];



export default DrawerItems;