import { Box, Grid, Theme, useMediaQuery } from "@mui/material";
import LoginForm from "./LoginForm";
import splash1 from "../../assets/png/STYLING-2.jpg";
import cornerImage from "../../assets/png/DigiForce_Logo.png"; // Add your small image path

function Login() {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <Grid
      container
      sx={{
        padding: 0,
        margin: 0,
        height: "100vh",
        backgroundImage: `url(${splash1})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        flexDirection: "row-reverse",
        position: "relative", // Ensure relative positioning for child absolute elements
      }}
    >
      <Grid
        item
        xs={12}
        sm={8}
        md={3}
        sx={{
          position: "relative",
          backdropFilter: "blur(8px)", // Blur effect for the background
          backgroundColor: "rgba(255, 255, 255, 0.35)", // Semi-transparent white background
          borderRadius: "16px", // Rounded corners for a sleek look
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Optional shadow for emphasis
        }}
      >
        <Box display="flex" justifyContent="center">
          <LoginForm />
        </Box>
      </Grid>

      {/* Centered Image in a White Box */}
      <Box
        sx={{
          position: "absolute",
          bottom: 0, // Align the box with the bottom edge
          left: "15%", // Move the box towards the center-right horizontally
          width: "200px", // Size of the box
          height: "150px", // Ensure it is square for centering
          backgroundColor: "#fff", // White background
          display: "flex", // Flexbox for centering the image
          alignItems: "center",
          justifyContent: "center",
          // borderRadius: "8px", // Optional: Add rounded corners
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Optional: Add a subtle shadow
        }}
      >
        <Box
          component="img"
          src={cornerImage}
          alt="Corner Decorative"
          sx={{
            width: "80%", // Make the image fit within the box
            height: "auto", // Maintain aspect ratio
          }}
        />
      </Box>
    </Grid>
  );
}

export default Login;
