import React from "react";
import { Grid, Typography, Skeleton } from "@mui/material";
import { useFirstLoad } from "../../../hooks/useSkeloton";

type StatusCardProps = {
    value: number | string;
    bgColor: string;
    title: string;
    fontColor: string;
    cardSize: number;
    onClick: () => void;
};

const StatusCard: React.FC<StatusCardProps> = ({
    value,
    cardSize,
    bgColor,
    title,
    fontColor,
    onClick,
}) => {
    const { isFirstLoad } = useFirstLoad();

    return (
        <Grid
            item
            xs={cardSize}
            sx={{
                backgroundColor: bgColor,
                borderRadius: "8.5px",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                padding: "10px",
            }}
            onClick={onClick}
        >
            {isFirstLoad ? (
                <Skeleton
                    variant="text"
                    width={60} // Adjust width to match value display
                    height={28} // Adjust height to match value font size
                />
            ) : (
                <Typography fontWeight="600" fontSize="1.5rem" style={{ color: fontColor }}>
                    {value}
                </Typography>
            )}
            <Typography
                fontWeight="500"
                fontSize="0.8rem"
                style={{ color: fontColor, whiteSpace: "nowrap" }}
            >
                {title}
            </Typography>
        </Grid>
    );
};

export default StatusCard;
