import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, Skeleton } from "@mui/material";
import DataTable from "../../components/dashboard/DataTableComponents/Table";
import { gql, useLazyQuery } from "@apollo/client";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import DashVehicleCountCard from "../../components/dashboard/VehicleCountComponents/DashboardVehicleCountCard";
import HealthStatus from "../../components/dashboard/HealthCountComponents/HealthStatus";
import TimePeriodSelector from "../../components/Miscellaneous/TimePeriodSelector";
import {
  DashboardData,
  DtcOccurrence,
  LiveVehicle,
  StatusOccurence,
} from "../../types";
import ServiceStatusCard from "../../components/dashboard/VehicleCountComponents/ServiceStatus";
import InducementDataTable from "../../components/dashboard/DataTableComponents/InducementTable";
import RegenerationTable from "../../components/dashboard/DataTableComponents/RegenerationTable";
import CreepModeCard from "../../components/dashboard/VehicleCountComponents/CreepModeCount";
import AdblueLevelTable from "../../components/dashboard/AdblueComponents/AdblueLevelTable";
import { useFirstLoad } from "../../hooks/useSkeloton";


const GET_DASHBOARD_DATA = gql`
  query dashboardData($fromTs: Int!, $toTs: Int!) {
    dashboardData(fromTs: $fromTs, toTs: $toTs) {
      dieselDtcs {
        dtc
        count
      }
      cngDtcs {
        dtc
        count
      }
      vehicleIssues {
        status
        count
      }
      healthIssues {
        status
        count
      }
      distanceToService {
        status
        count
      }
      trackingStatus {
        status
        count
      }
      adblueData {
        status
        count
      }
    }
  }
`;

const GET_ALL_VEHICLE_LIVE_STATUS = gql`
  query GetAllVehicleLiveStatus {
    getAllVehicleLiveStatus {
      uniqueId
      vehicleNumber
      chassisNumber
    }
  }
`;


const Home = () => {
  const [topWarningLampData, setTopWarningLampData] = useState<
    StatusOccurence[]
  >([]);
  const [topDieselDtc, setTopDieselDtc] = useState<DtcOccurrence[]>([]);
  const [topCngDtc, setTopCngDtc] = useState<DtcOccurrence[]>([]);
  const [allVehicleLiveStatus, setAllVehicleLiveStatus] = useState<
    LiveVehicle[]
  >([]);
  const [selectedVehicle, setSelVel] = useState<LiveVehicle>();
  const [fromTime, setfromTime] = useState<number>(
    Math.floor(new Date().setHours(0, 0, 0, 0) / 1000)
  );
  const [option, setOption] = useState<string>("WEEK");
  const [toTime, settoTime] = useState<number>(Math.floor(Date.now() / 1000));
  const [isDefaultSelected, setIsDefaultSelected] = useState(true);
  const [customDieselDtcs, setCustomDieselDtcs] = useState<DtcOccurrence[]>([]);
  const [customCngDtcs, setCustomCngDtcs] = useState<DtcOccurrence[]>([]);
  const [trackingStatus, setTrackingStatus] = useState<StatusOccurence[]>([]);
  const [healthIssues, setHealthIssues] = useState<StatusOccurence[]>([]);
  const [distanceToService, setDistanceToService] = useState<StatusOccurence[]>([]);
  const { isFirstLoad, markLoaded } = useFirstLoad();
  

  const [
    getDashboardData,
    {
      loading: loadingDashboardData,
      data: dashboardData,
      error: errorDashboardData,
    },
  ] = useLazyQuery(GET_DASHBOARD_DATA, {
    fetchPolicy: "network-only",
    variables: { fromTs: fromTime, toTs: toTime },
    onCompleted: (data: { dashboardData: DashboardData }) => {
      setTopWarningLampData(data.dashboardData.vehicleIssues);
      setTopDieselDtc(data.dashboardData.dieselDtcs);
      setTopCngDtc(data.dashboardData.cngDtcs);
      setTrackingStatus(data.dashboardData.trackingStatus);
      setHealthIssues(data.dashboardData.healthIssues);
      setDistanceToService(data.dashboardData.distanceToService);
    },
  });

  const [
    getAllVehicleLiveStatus,
    {
      loading: liveVehicleDataLoading,
      data: liveVehicleData,
      error: errorLiveVehicleData,
    },
  ] = useLazyQuery(GET_ALL_VEHICLE_LIVE_STATUS, {
    variables: { fromTime, toTime },
    onCompleted: (data: { getAllVehicleLiveStatus: LiveVehicle[] }) => {
      setAllVehicleLiveStatus(data.getAllVehicleLiveStatus);
    },
  });

  const handleOptionChange = (e: React.ChangeEvent<{ value: string }>) => {
    setOption(e.target.value);
  };

  const handleSubmit = (event: React.ChangeEvent<{ value: string }>) => {
    if (fromTime && fromTime >= toTime) {
      enqueueSnackbar("Date range provided is wrong");
    } else if (
      (fromTime && fromTime > moment(moment.now()).unix()) ||
      toTime > moment(moment.now()).unix()
    ) {
      enqueueSnackbar("Future dates are not allowed");
    } else {
      setfromTime(fromTime);
      settoTime(toTime);
    }
  };

  const handleSelectVehicle = (
    v: React.ChangeEvent<HTMLInputElement>,
    sel: any
  ) => {
    setSelVel(sel);
  };

  const creepModeCount =
    topDieselDtc.find((dtc) => dtc.dtc === "P1060")?.count || 0;

  const P2BA9Count = topDieselDtc.find((dtc) => dtc.dtc === "P2AB9")?.count || 0;

  const P2BABCount = topDieselDtc.find((dtc) => dtc.dtc === "P2BAB")?.count || 0;

  const P2ABCCount = topDieselDtc.find((dtc) => dtc.dtc === "P2BAC")?.count || 0;

  const adblueDtcCount = P2BA9Count + P2BABCount + P2ABCCount;

  const adblueDataUpdated = dashboardData?.dashboardData?.adblueData
    ? [
      ...dashboardData.dashboardData.adblueData,
      { status: "P2BA9", count: adblueDtcCount },
    ]
    : [];

  useEffect(() => {
    setIsDefaultSelected(option === "CUSTOM" || option === "");
    if (option !== "CUSTOM" && option !== "") {
      const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
      switch (option) {
        case "HOUR":
          setfromTime(currentTimeInSeconds - 60 * 60); // Last 1 hour
          break;
        case "DAY":
          setfromTime(currentTimeInSeconds - 24 * 60 * 60); // Last 1 day
          break;
        case "WEEK":
          setfromTime(currentTimeInSeconds - 7 * 24 * 60 * 60); // Last 7 days
          break;
        case "MONTH":
          setfromTime(currentTimeInSeconds - 30 * 24 * 60 * 60); // Last 30 days
          break;
        default:
          break;
      }
    }
  }, [option]);

  useEffect(() => {
    const fetchData = async () => {
      await getDashboardData();
      if (isFirstLoad) {
        markLoaded(); // Mark load as complete as soon as data is ready
      }
    };
    fetchData();
    const interval = setInterval(fetchData, 10000);
    return () => clearInterval(interval);
  }, [fromTime, toTime, getDashboardData, isFirstLoad, markLoaded]);

  useEffect(() => {
    getAllVehicleLiveStatus();
  }, [fromTime, toTime, getAllVehicleLiveStatus]);

  useEffect(() => {
    const customDieselDtcList = JSON.parse(
      localStorage.getItem("customDieselDtcList") || "[]"
    );
    const customCngDtcList = JSON.parse(
      localStorage.getItem("customCngDtcList") || "[]"
    );
    const dieselDtcs = dashboardData?.dashboardData?.dieselDtcs || [];
    const cngDtcs = dashboardData?.dashboardData?.cngDtcs || [];
    const customDieselDtcsWithCount = customDieselDtcList.map(
      (customDtc: string) => {
        const dtcData = dieselDtcs.find(
          (dtc: DtcOccurrence) => dtc.dtc === customDtc
        );
        return {
          dtc: customDtc,
          count: dtcData ? dtcData.count : 0,
        };
      }
    );
    const customCngDtcsWithCount = customCngDtcList.map((customDtc: string) => {
      const dtcData = cngDtcs.find(
        (dtc: DtcOccurrence) => dtc.dtc === customDtc
      );
      return {
        dtc: customDtc,
        count: dtcData ? dtcData.count : 0,
      };
    });
    setCustomDieselDtcs(customDieselDtcsWithCount);
    setCustomCngDtcs(customCngDtcsWithCount);
  }, [dashboardData]);
  
  console.log(isFirstLoad);
  

  return (
    <Box justifyContent="flex-start" sx={{ backgroundColor: "#F6F5F4", padding: 2 }} maxHeight={"100vh"}>
      <Grid container spacing={2} justifyContent="flex-start" sx={{ padding: 2 }}>
        <Grid item xs={4}>
          <Typography sx={{ fontSize: 32, fontWeight: "semi-bold", textAlign: "left" }}>
            Dashboard
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <TimePeriodSelector
            option={option}
            setFromTs={setfromTime}
            fromTs={fromTime}
            toTs={toTime}
            setToTs={settoTime}
            onOptionChange={handleOptionChange}
            handleSubmit={handleSubmit}
            handleSelectVehicle={handleSelectVehicle}
            vehicles={allVehicleLiveStatus}
            isDefaultSelected={isDefaultSelected}
            selectedVehicle={selectedVehicle}
            isDashBoard={true}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="start">
        <Grid item xs={12} md={4}>
          <Typography variant="caption" color="textSecondary">
            Live data updated at: {new Date().toLocaleTimeString()}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DashVehicleCountCard trackingStatus={trackingStatus} />
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="flex-start" marginTop={"8px"}>
        <Grid item xs={12} md={4}>
            <HealthStatus healthIssues={healthIssues} />
        </Grid>
        <Grid item xs={12} md={3}>
            <ServiceStatusCard serviceStatus={distanceToService} />
        </Grid>
        <Grid item xs={12} md={4}>
            <AdblueLevelTable adblueData={adblueDataUpdated} />
        </Grid>
        <Grid item xs={12} md={1}>
           <CreepModeCard creepModeCount={creepModeCount} />
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="flex-start">
        <Grid item xs={7} sx={{ height: '100%' }}>
          <InducementDataTable dieselDtcs={topDieselDtc} />
        </Grid>
        <Grid item xs={5} sx={{ height: '100%' }}>
          <RegenerationTable dieselDtcs={topDieselDtc} />
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="flex-start">
        <Grid item xs={12} md={4}>
          <DataTable data={topWarningLampData} tableName="Top Warning Lamps" queryType="WarningLamp" />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataTable data={topDieselDtc} tableName="Top Diesel DTC Codes" queryType="DTC" />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataTable data={topCngDtc} tableName="Top CNG DTC Codes" queryType="DTC" />
        </Grid>
        <Grid container spacing={2} justifyContent={"flex-start"}>
          {customDieselDtcs.length > 0 && (
            <Grid item xs={12} md={4}>
              <DataTable
                data={customDieselDtcs}
                tableName="TOP 10 Custom Diesel DTC"
                queryType="DTC" />
            </Grid>
          )}
          {customCngDtcs.length > 0 && (
            <Grid item xs={12} md={4}>
              <DataTable
                data={customCngDtcs}
                tableName="TOP 10 Custom CNG DTC"
                queryType="DTC"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;


