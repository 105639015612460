import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Paper,
  Typography,
  Grid,
} from "@mui/material";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";

// Interface for generic data
interface Data {
  [key: string]: number;
}

interface DataPercentageTableProps {
  dataProp: Data; // Data passed as prop (gear, speed, torque, etc.)
  liveData?: Data; // Optional live data
  labels: { [key: string]: string }; // Labels for mapping keys
  dataType: "Gear" | "Speed" | "Torque"; // Type of data for display
}

const DataPercentageTable: React.FC<DataPercentageTableProps> = ({
  dataProp,
  liveData,
  labels,
  dataType,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  // Function to calculate percentages from data
  const calculatePercentages = (data: Data) => {
    const totalUsage = Object.values(data).reduce((sum, count) => sum + count, 0);
    return Object.keys(data).map((key) => ({
      key,
      percentage: ((data[key] / totalUsage) * 100).toFixed(2), // Keep as a string with two decimal places
    }));
  };

  const percentagesForTable = calculatePercentages(dataProp);
  
  const percentagesForPie = percentagesForTable.map((item) => ({
    name: labels[item.key],
    value: Math.floor(parseFloat(item.percentage)), // Floor for pie chart
  }));

  const livePercentages = liveData ? calculatePercentages(liveData) : [];

  // Take the top 10 values in the original order
  const topTenData = percentagesForPie.slice(0, 10);

  // Colors for the pie chart slices
  const COLORS = [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#ff8042",
    "#8dd1e1",
    "#a4de6c",
    "#d0ed57",
    "#ffbb28",
    "#ff7f50",
    "#ff6347",
  ];

  // Conditional check for data availability
  const isDataAvailable = percentagesForTable.length > 0;

  return (
    <Box>
      <Paper elevation={3} sx={{ width: "100%", mb: 1, height: 357 }}>
        <Box sx={{ p: 1 }}>
          <Typography variant="h6">{dataType} Usage Percentage</Typography>
        </Box>
        {isDataAvailable ? (
          <Grid container spacing={2}>
            {/* Pie Chart on the left side */}
            <Grid
              item
              xs={12}
              md={5}
              lg={5}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <Box sx={{ width: "100%" }}>
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <Pie
                      data={topTenData}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={100}
                      innerRadius={60}
                      labelLine={false}
                      onMouseEnter={(_, index) => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      {topTenData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip formatter={(value: any) => `${value}%`} />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </Grid>
            {/* Table on the right side */}
            <Grid item xs={12} md={7} lg={7}>
              <Box sx={{ overflowY: "hidden", overflowX: "hidden", height: 305 }}>
                <Table sx={{ minWidth: "325px" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>{dataType}</TableCell>
                      <TableCell sx={{ textAlign: "right" }}>Percentage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {topTenData.map((item, index) => (
                      <TableRow
                        key={index}
                        sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" } }}
                      >
                        <TableCell sx={{ position: "relative", padding: "8px 16px", fontWeight: "bold" }}>
                          {/* Colored line indicator cell */}
                          <Box
                            sx={{
                              position: "absolute",
                              left: 0,
                              top: "50%",
                              transform: "translateY(-50%)",
                              width: 4,
                              height: 20,
                              backgroundColor: COLORS[index % COLORS.length],
                              borderRadius: 2,
                            }}
                          />
                          {item.name}
                        </TableCell>
                        <TableCell sx={{ p: 1, textAlign: "right" }}>{item.value}%</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Grid>
          </Grid>
        ) : (
          // No data available message
          <Box sx={{ p: 2, textAlign: "center" }}>
            <Typography variant="h6" color="textSecondary">
              No data available
            </Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default DataPercentageTable;
