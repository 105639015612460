import { Typography, Box, Grid, Skeleton } from "@mui/material";
import theme from "../../../theme";
import { useFirstLoad } from "../../../hooks/useSkeloton";

function CountCard({
  value,
  icon,
  title,
  color,
  onClick,
}: {
  value: number | string;
  icon: any;
  title: string;
  color: string;
  onClick: () => void;
}) {
  const { isFirstLoad } = useFirstLoad();

  return (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: "#F6F5F4",
        borderRadius: 1,
        padding: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid container columnGap={1}>
        {/* Icon Section */}
        <Grid
          item
          xs={3}
          sx={{
            bgcolor: color,
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
            <Box
              component="img"
              src={icon}
              sx={{
                height: "3rem",
                paddingBottom: 1,
                paddingTop: 1,
              }}
            />
        </Grid>

        {/* Text Section */}
        <Grid item xs={7}>
          <Grid container display="flex">
            {/* Value */}
            <Grid item xs={12}>
              {isFirstLoad ? (
                <Skeleton variant="text" width="80%" height={24} />
              ) : (
                <Typography fontWeight="900" fontSize="1rem">
                  {value}
                </Typography>
              )}
            </Grid>
            {/* Title */}
            <Grid item xs={12}>
              {isFirstLoad ? (
                <Skeleton variant="text" width="60%" height={20} />
              ) : (
                <Typography fontWeight="500" fontSize="0.8rem">
                  {title}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CountCard;
