import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Paper,
  Typography,
  Skeleton,
} from "@mui/material";
import { DtcOccurrence } from "../../../types";
import DetailDialog from "./DetailDialog";
import { useFirstLoad } from "../../../hooks/useSkeloton";

interface InducementTableProps {
  dieselDtcs: DtcOccurrence[];
}

const InducementTable: React.FC<InducementTableProps> = ({ dieselDtcs }) => {
  const categories = [
    { name: "EGR valve", dtcs: { warning: "P1106", level1: "P1104", level3: "P1105" } },
    { name: "Dosing Interruption", dtcs: { warning: "P1109", level1: "P1107", level3: "P1108" } },
    { name: "Reagent Consumption", dtcs: { warning: "P1115", level1: "P1113", level3: "P1114" } },
    { name: "Tampering", dtcs: { warning: "P1123", level1: "P1121", level3: "P1122" } },
    { name: "Reagent Quality", dtcs: { warning: "P1120", level1: "P1116", level3: "P1117" } },
    { name: "Urea Level", dtcs: { warning: "P1110", level1: "P203D" } },
  ];

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedName, setSelectedName] = useState<string>("");
  const {isFirstLoad} = useFirstLoad();

  const handleClickOpen = (name: string) => {
    setSelectedName(""); // Reset selectedName temporarily to force re-render
    setTimeout(() => {
      setSelectedName(name);
      setDialogOpen(true);
    }, 0);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedName("");
  };

  const tableData = categories.map((category) => {
    const warningData = dieselDtcs.find((dtc) => dtc.dtc === category.dtcs.warning);
    const level1Data = dieselDtcs.find((dtc) => dtc.dtc === category.dtcs.level1);
    const level3Data = dieselDtcs.find((dtc) => dtc.dtc === category.dtcs.level3);
    
    return {
      category: category.name,
      warningDtc: category.dtcs.warning,
      warningCount: warningData ? warningData.count : 0,
      level1Dtc: category.dtcs.level1,
      level1Count: level1Data ? level1Data.count : 0,
      level3Dtc: category.dtcs.level3,
      level3Count: level3Data ? level3Data.count : 0,
    };
  });

  return (
    <Box sx={{ mt: 2 }}>
      <Paper elevation={3} sx={{ width: "100%", height: "390px", mb: 1, overflowX: "auto" }}>
        <Box
          sx={{
            p: 2,
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            zIndex: 2,
          }}
        >
          <Typography variant="h6">Inducement Vehicles</Typography>
        </Box>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  position: "sticky",
                  top: "32px", // Adjusted to be below the title
                  zIndex: 1,
                  backgroundColor: "white",
                }}
              >
                Inducement Categories
              </TableCell>
              <TableCell
                sx={{
                  position: "sticky",
                  top: "32px", // Adjusted to be below the title
                  zIndex: 1,
                  borderLeft: '1px solid rgba(224, 224, 224, 1)',
                  backgroundColor: "white",
                }}
              >
                Inducement Warning DTC
              </TableCell>
              <TableCell
                sx={{
                  position: "sticky",
                  top: "32px", // Adjusted to be below the title
                  zIndex: 1,
                  borderRight: '1px solid rgba(224, 224, 224, 1)',
                  backgroundColor: "white",
                }}
              >
                No. of vehicles
              </TableCell>
              <TableCell
                sx={{
                  position: "sticky",
                  top: "32px", // Adjusted to be below the title
                  zIndex: 1,
                  backgroundColor: "white",
                }}
              >
                Level 1 Inducement (25% torque reduction)
              </TableCell>
              <TableCell
                sx={{
                  position: "sticky",
                  top: "32px", // Adjusted to be below the title
                  zIndex: 1,
                  borderRight: '1px solid rgba(224, 224, 224, 1)',
                  backgroundColor: "white",
                }}
              >
                No. of vehicles
              </TableCell>
              <TableCell
                sx={{
                  position: "sticky",
                  top: "32px", // Adjusted to be below the title
                  zIndex: 1,
                  backgroundColor: "white",
                }}
              >
                Level 3 Inducement (20 kmph speed limit)
              </TableCell>
              <TableCell
                sx={{
                  position: "sticky",
                  top: "32px", // Adjusted to be below the title
                  zIndex: 1,
                  borderRight: '1px solid rgba(224, 224, 224, 1)',
                  backgroundColor: "white",
                }}
              >
                No. of vehicles
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ fontWeight: "bold" }}>{row.category}</TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    borderLeft: '1px solid rgba(224, 224, 224, 1)',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  onClick={() => handleClickOpen(row.warningDtc)}
                >
                  {isFirstLoad ? <Skeleton width={80} /> : row.warningDtc}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  onClick={() => handleClickOpen(row.warningDtc)}
                >
                  {isFirstLoad ? <Skeleton width={50} /> : row.warningCount.toString()}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  onClick={() => handleClickOpen(row.level1Dtc)}
                >
                  {isFirstLoad ? <Skeleton width={80} /> : row.level1Dtc}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  onClick={() => handleClickOpen(row.level1Dtc)}
                >
                  {isFirstLoad ? <Skeleton width={50} /> : row.level1Count}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  onClick={() => row.level3Dtc ? handleClickOpen(row.level3Dtc) : () => {}}
                >
                  {isFirstLoad ? <Skeleton width={80} /> : row.level3Dtc}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  onClick={() => row.level3Dtc ? handleClickOpen(row.level3Dtc) : () => {}}
                >
                  {isFirstLoad ? <Skeleton width={50} /> : row.level3Count}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <DetailDialog open={dialogOpen} onClose={handleClose} name={selectedName} queryType={"DTC"} />
    </Box>
  );
};

export default InducementTable;
