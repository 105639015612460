import React, { useState } from "react";
import { Grid, Paper, useMediaQuery } from "@mui/material";
import MyFleet from "../../../assets/smlIcons/Dashboard Icons/My Fleet.svg";
import Running from "../../../assets/smlIcons/Dashboard Icons/Running.svg";
import Stopped from "../../../assets/smlIcons/Dashboard Icons/Stopped.svg";
import NoGPS from "../../../assets/smlIcons/Dashboard Icons/No GPS.svg";
import Offline from "../../../assets/smlIcons/Dashboard Icons/Offline.svg";
import Idle from "../../../assets/smlIcons/Dashboard Icons/Idling.svg";
import NoData from "../../../assets/smlIcons/Dashboard Icons/No Data.svg";
import theme from "../../../theme";
import CountCard from "./CountCard"; // Adjust the path as needed
import DetailDialog from "../DataTableComponents/DetailDialog";
import { StatusOccurence } from "../../../types";

type StatusOccurrence = {
  count: number;
  status: "RUNNING" | "HALT" | "NO_GPS" | "OFFLINE" | "IDLE" | "NODATA";
};

interface DashVehicleCountCardProps {
  trackingStatus: StatusOccurence[];
}

const DashVehicleCountCard: React.FC<DashVehicleCountCardProps> = ({
  trackingStatus,
}) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedName, setSelectedName] = useState<string | null>(null);

  const handleClickOpen = (status: string) => {
    if(status !== "MY_FLEETS") {
      setSelectedName(status);
      setDialogOpen(true);
    }else{
      setSelectedName(null);
      setDialogOpen(false);
    }

  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedName(null);
  };

  const getStatusCount = (status: string) => {
    return trackingStatus.find((item) => item.status === status)?.count || 0;
  };

  const items = [
    {
      title: "My Fleets",
      value: trackingStatus.reduce((acc, item) => acc + item.count, 0),
      icon: MyFleet,
      color: theme.palette.grey4.main,
      status: "MY_FLEETS",
    },
    {
      title: "Running",
      value: getStatusCount("RUNNING"),
      icon: Running,
      color: theme.palette.lightgreen.main,
      status: "RUNNING",
    },
    {
      title: "Idle",
      value: getStatusCount("IDLE"),
      icon: Idle,
      color: theme.palette.lightyellow.main,
      status: "IDLE",
    },
    {
      title: "Halt",
      value: getStatusCount("HALT"),
      icon: Stopped,
      color: theme.palette.lightred.main,
      status: "HALT",
    },
    {
      title: "No GPS",
      value: getStatusCount("NO_GPS"),
      icon: NoGPS,
      color: theme.palette.grey2.main,
      status: "NO_GPS",
    },
    {
      title: "No Data",
      value: getStatusCount("NODATA"),
      icon: NoData,
      color: theme.palette.grey2.main,
      status: "NODATA",
    },
    {
      title: "Offline",
      value: getStatusCount("OFFLINE"),
      icon: Offline,
      color: theme.palette.grey2.main,
      status: "OFFLINE",
    },
  ];

  return (
    <>
      <Paper elevation={3} sx={{ padding: 2, borderRadius: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid
              container
              spacing={1}
              justifyContent="space-around"
              sx={{ flexWrap: "nowrap" }}
            >
              {items.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={isSmallScreen ? 6 : 3}
                  md={isSmallScreen ? 6 : 2.4}
                  key={index}
                  sx={{ flexBasis: "auto", }}
                >
                  <CountCard
                    value={item.value}
                    icon={item.icon}
                    title={item.title}
                    color={item.color}
                    onClick={() => handleClickOpen(item.status)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <DetailDialog
        open={dialogOpen}
        onClose={handleClose}
        name={selectedName}
        queryType={"TrackingStatus"}
      />
    </>
  );
};

export default DashVehicleCountCard;
