import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Paper,
  Typography,
  Grid,
  Skeleton,
} from "@mui/material";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import DetailDialog from "./DetailDialog"; // Import the dialog component
import { useFirstLoad } from "../../../hooks/useSkeloton";

interface DataTableProps {
  data: { [key: string]: any }[];
  tableName: string;
  queryType: "WarningLamp" | "DTC" | "HealthStatus" | "None";
}

const DataTable: React.FC<DataTableProps> = ({ data, tableName, queryType }) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedName, setSelectedName] = useState<string | null>(null);

  const loading = useFirstLoad();

  const handleClickOpen = (name: string) => {
    setSelectedName(name);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedName(null);
  };

  // Extracting headers from the first object in the data array, excluding '__typename'
  const headers = data.length > 0 ? Object.keys(data[0]).filter(header => header !== "__typename") : [];

  // Function to format cell values
  const formatValue = (value: any) => {
    if (typeof value === "string") {
      return value.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase()); // Replace underscores with spaces
    }
    return value;
  };

  // Generate data for the pie chart using floored percentages
  const pieData = data.map((row) => ({
    name: `${row[headers[0]]}`,
    value: Math.floor(row[headers[1]]), // Use floored values for the pie chart
  }));

  // Sort and get top 10 values
  const topTenData = pieData.sort((a, b) => b.value - a.value).slice(0, 10);

  // Colors for the pie chart slices
  const COLORS = [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#ff8042",
    "#8dd1e1",
    "#a4de6c",
    "#d0ed57",
    "#ffbb28",
    "#ff7f50",
    "#ff6347",
  ];

  // Create a color map to ensure consistent color use between table and pie chart
  const colorMap: { [key: string]: string } = {};
  topTenData.forEach((entry, index) => {
    colorMap[entry.name] = COLORS[index % COLORS.length];
  });

  return (
    <Box sx={{ mt: 2 }}>
      <Paper elevation={3} sx={{ width: "100%", mb: 1 }}>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6">{tableName}</Typography>
        </Box>
        <Grid container spacing={2}>
          {/* Doughnut Chart on the left side */}
          <Grid item xs={12} md={5} lg={5} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ width: "100%", height: 300 }}>
              {loading.isFirstLoad ? (
                <Skeleton variant="circular" width={200} height={200} />
              ) : (
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={topTenData}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={100}
                      innerRadius={60}
                      labelLine={false}
                      onMouseEnter={(_, index) => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      {topTenData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colorMap[entry.name]} />
                      ))}
                    </Pie>
                    <Tooltip formatter={(value: any) => `${value}`} />
                  </PieChart>
                </ResponsiveContainer>
              )}
            </Box>
          </Grid>
  
          {/* Table on the right side */}
          <Grid item xs={12} md={7} lg={7}>
            <Box sx={{ height: 300, overflow: "auto" }}>
              {loading.isFirstLoad ? (
                <>
                  <Skeleton variant="text" width="100%" height={40} />
                  <Skeleton variant="rectangular" width="100%" height={200} />
                </>
              ) : (
                <Table sx={{ minWidth: "320px" }}>
                  <TableHead>
                    <TableRow>
                      {headers.map((header, index) => (
                        <TableCell
                          key={index}
                          sx={{ fontWeight: "bold", textTransform: "capitalize", p: 1 }}
                        >
                          {formatValue(header)}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.slice(0, 10).map((row, rowIndex) => (
                      <TableRow
                        key={rowIndex}
                        sx={{
                          "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                        }}
                      >
                        <TableCell
                          sx={{
                            position: "relative",
                            padding: "8px 16px",
                            fontWeight: "bold",
                          }}
                          onClick={() => handleClickOpen(row[headers[0]])}
                        >
                          <Box
                            sx={{
                              position: "absolute",
                              left: 0,
                              top: "50%",
                              transform: "translateY(-50%)",
                              width: 4,
                              height: 20,
                              backgroundColor: colorMap[row[headers[0]]],
                              borderRadius: 2,
                            }}
                          />
                          {formatValue(row[headers[0]])}
                        </TableCell>
                        {headers.slice(1).map((header, cellIndex) => (
                          <TableCell key={cellIndex} sx={{ p: 1, cursor: "default" }}>
                            {formatValue(row[header])}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
      {queryType !== "None" && (
        <DetailDialog open={dialogOpen} onClose={handleClose} name={selectedName} queryType={queryType} />
      )}
    </Box>
  );
  
};

export default DataTable;
